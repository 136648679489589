.rbc-date-cell > a, 
.rbc-btn-group {
    background-color: #323232; /* Altere para qualquer cor escura que você preferir */
    color: #ffffff; /* Isso fará com que o texto seja branco */
}

.rbc-btn-group button {
    background-color: #323232; 
    color: #ffffff;
    border: none;
}

.rbc-btn-group button:hover {
    background-color: #3174ad; /* Cor mais escura para o estado de hover */
}

.rbc-date-cell > a:hover {
    background-color: #424242; /* Cor mais escura para o estado de hover */
    color: #3174ad; 
}

.rbc-btn-group > .rbc-active {
    background-color: #424242; /* Cor mais escura para o estado de hover */
    color: #424242; 
}
